<template>
    <div>
        <b-container>
            <section class="admin">
                <div class="sector">
                    <AdminNavigation />
                    <h2 class="main-title">Manage payments</h2>
                    <div class="clearfix">
                        <router-link to="/add-admin-payment" class="details-button details-button--red float-right"><simple-line-icons icon="arrowRight" size="small" color="#504e70" />Add payment</router-link>
                    </div>
                    <div v-if="!getAdminPaymentsListPending" class="mb-3 mt-3">
                        <Spinner size="medium" line-fg-color="#e91e63" />
                    </div>
                    <div v-else class="admin__box">
                        <div class="admin__table">
                            <div class="input-box">
                                <div v-if="filterPayments" @click.prevent="clearFilter" class="clear-icon clear-icon--filter">
                                    <simple-line-icons icon="close" size="small" color="#757575" />
                                </div>
                                <input v-model="filterPayments" class="custom-search-bar" type="text" placeholder="Filter" />
                            </div>
                            <b-table
                                hover
                                id="pendingAdminPayments"
                                :items="getSortedAdminPaymentsList"
                                :fields="fields"
                                :per-page="perPage"
                                :current-page="Number(currentPage)"
                                :filter="filterPayments"
                                responsive>
                                <template slot="amount" slot-scope="data">
                                    <span>${{ data.item.amount }}</span>
                                </template>
                                <template slot="manage" slot-scope="data">
                                    <span>
                                        <div>
                                            <router-link :to="{ name: 'edit-admin-payment', params: { id: data.item.id }}" class="details-button details-button--default mt-1 mr-1 nowrap"><simple-line-icons icon="arrowRight" size="small" color="#504e70" />Edit</router-link>
                                            <button @click.prevent="deleteAdminPayment(data.item.id)" :disabled="getDisabledAdminPayment" :class="getDisabledAdminPayment ? 'disabled-element' : ''" class="details-button details-button--dark-red mt-1 mr-1 mb-0 nowrap"><simple-line-icons icon="close" size="small" color="#fff" />Delete</button>
                                        </div>
                                    </span>
                                </template>
                                <template slot="invoice" slot-scope="data">
                                   <a :href="data.item.invoice" class="pdf-link">Download</a>
                                </template>
                            </b-table>
                            <b-pagination
                                @change="scrollToTop('pendingAdminPayments');"
                                v-model="currentPage"
                                :total-rows="rows"
                                :per-page="perPage"
                                aria-controls="pendingAdminPayments"
                            ></b-pagination>
                            <label class="mr-2" for="paginationInput">Go to page:</label><b-form-input class="pagination__input" id="paginationInput" v-model="currentPage"></b-form-input>
                        </div>
                    </div>
                </div>
            </section>
        </b-container>
    </div>
</template>

<script>
import SimpleLineIcons from 'vue-simple-line'
import { mapGetters } from 'vuex'
import Spinner from 'vue-simple-spinner'
import AdminNavigation from '@/components/AdminNavigation'
import MoveTo from 'moveto'
import moment from 'moment'
export default {
  components: {
    SimpleLineIcons,
    Spinner,
    AdminNavigation
  },
  data: function () {
    return {
      perPage: 5,
      currentPage: '1',
      filterPayments: '',
      fields: [
        { key: 'user', label: 'Uid', sortable: true },
        { key: 'transactionNumber', label: 'Transaction id', sortable: true },
        { key: 'subscriptionType', label: 'Subscription type', sortable: true },
        { key: 'amount', label: 'Amount', sortable: true },
        { key: 'manage', label: 'Manage', sortable: false },
        { key: 'date', label: 'Date', sortable: true },
        { key: 'customer', label: 'Customer', sortable: true },
        { key: 'invoice', label: 'Invoice', sortable: false }
      ]
    }
  },
  methods: {
    clearFilter () {
      this.filterPayments = ''
    },
    scrollToTop (element) {
      const moveTo = new MoveTo({
        tolerance: 75,
        duration: 1000
      })

      const target = document.getElementById(element)

      moveTo.move(target)
    },
    deleteAdminPayment (id) {
      this.$swal({
        type: 'warning',
        title: 'Warning',
        text: 'Are you sure you want to delete this payment?',
        showCancelButton: true,
        cancelButtonText: 'No',
        confirmButtonText: 'Yes',
        confirmButtonColor: '#f1352e',
        cancelButtonColor: '#13b81b',
        focusCancel: true
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch('setDisabledAdminPayment')
          this.$store.dispatch('deleteAdminPayment', { id: id })
            .then(() => {

            })
            .catch(error => {
              this.$store.dispatch('clearDisabledAdminPayment')
            })
        }
      })
    }
  },
  computed: {
    ...mapGetters([
      'getDisabledAdminPayment',
      'getAdminPaymentsListPending',
      'getAdminPaymentsList'
    ]),
    getSortedAdminPaymentsList () {
      var sorted = this.getAdminPaymentsList.map(function (payment) {
        return {
          id: payment.id,
          user: payment.user,
          transactionNumber: payment.number,
          subscriptionType: (payment.type == 2 ? 'STANDARD' : payment.type == 3 ? 'PREMIUM' : payment.type == 4 ? 'ULTIMATE' : 'NONE'),
          amount: payment.amount,
          date: moment.unix(payment.date).format('DD/MM/YYYY HH:mm'),
          customer: payment.customer,
          invoice: payment.invoice
        }
      })

      return sorted.reverse()
    },
    rows () {
      return this.getSortedAdminPaymentsList.length
    }
  },
  beforeCreate () {
    this.$store.dispatch('getAdminPaymentsList')
    this.$store.dispatch('clearDisabledAdminPayment')
  }
}
</script>
