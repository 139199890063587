<template>
    <div>
        <div class="admin__navigation">
            <router-link to="/115599" :class="this.$route.name == '115599' ? 'active-link' : ''" class="dektop-navigation__link" active-class="active-link" exact><simple-line-icons icon="ghost" size="small" color="#e91e63" />Admin panel</router-link>
            <router-link to="/admin-users" :class="this.$route.name == 'admin-users' ? 'active-link' : ''" class="dektop-navigation__link" active-class="active-link" exact><simple-line-icons icon="people" size="small" color="#e91e63" />Manage users</router-link>
            <router-link to="/admin-agencies" :class="this.$route.name == 'agency' ? 'active-link' : ''" class="dektop-navigation__link" active-class="active-link" exact><simple-line-icons icon="layers" size="small" color="#e91e63" />Manage agencies</router-link>
            <router-link to="/admin-events" :class="this.$route.name == 'event' ? 'active-link' : ''" class="dektop-navigation__link" active-class="active-link" exact><simple-line-icons icon="event" size="small" color="#e91e63" />Manage events</router-link>
            <router-link to="/admin-jobs" :class="this.$route.name == 'job' ? 'active-link' : ''" class="dektop-navigation__link" active-class="active-link" exact><simple-line-icons icon="briefcase" size="small" color="#e91e63" />Manage jobs</router-link>
            <router-link to="/admin-payments" class="dektop-navigation__link" active-class="active-link" exact><simple-line-icons icon="wallet" size="small" color="#e91e63" />Manage payments</router-link>
            <router-link to="/admin-logs" class="dektop-navigation__link" active-class="active-link" exact><simple-line-icons icon="shuffle" size="small" color="#e91e63" />Logs</router-link>
        </div>
    </div>
</template>

<script>
import SimpleLineIcons from 'vue-simple-line'
export default {
  components: {
    SimpleLineIcons
  }
}
</script>
